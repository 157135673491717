<template lang="pug">
  Transition(
    name="fade"
    tag="div"
    class="transition-container"
  )
    .modal(
      v-if="isOpen"
      key="modal"
      :class="{ open: isOpen }"
    )
      .modal-back(@mousedown="$emit('close')")
      .modal-wrapper#app-modal(:class="[{ open: isOpen }, size, background]")
        .header(v-if="!hideHeader")
          .title(v-if="title") {{ title }}
          .close
            FaIcon(icon="times" @click="$emit('close', true)")
        hr(v-if="headerLine")
        .modal-body
          slot
</template>

<script>
  export default {
    props: {
      isOpen: Boolean,
      title: String,
      hideHeader: Boolean,
      headerLine: Boolean,
      size: {
        type: String,
        default: "default",
        validator: value => ["default", "large"].includes(value)
      },
      background: {
        type: String,
        default: "white",
        validator: value => ["white", "gray"].includes(value)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/helpers.sass"
  @import "@/assets/styles/transitions.sass"

  .modal
    align-items: center
    background-color: rgba(43, 63, 87, 0.5)
    display: flex
    height: 100%
    left: 0
    justify-content: center
    position: fixed
    top: 0
    width: 100%
    z-index: 1000

    +ie-only
      flex-direction: column

    &-back
      width: 100%
      height: 100%
      position: fixed
      z-index: 10001

    &-wrapper
      border-radius: 0.3rem
      padding: 1.5rem 2rem
      max-width: 40rem
      min-width: 30rem
      max-height: 90vh
      z-index: 10002

      &.white
        background-color: $default-white

      &.gray
        background-color: $default-gray-light

      &.default
        max-width: 500px
        .modal-body
          max-height: calc(90vh - 10rem - 3.6rem)

      &.large
        margin: 5vh auto
        max-width: 90%
        .modal-body
          max-height: calc(90vh - 10vh - 3.6rem)

      .header
        align-items: center
        display: flex
        justify-content: space-between
        margin-bottom: 24px

        .title
          color: $default-black
          font-weight: 400
          font-size: 1.5rem

        .close
          svg
            cursor: pointer

          svg:hover
            opacity: 0.8

      hr
        background-color: $border-element-color

      .modal-body
        overflow-y: auto
        padding: $modal-padding

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .header
      .title
        font-family: sans-serif !important
        font-weight: 500
</style>
